/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    compass: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 16.016a7.5 7.5 0 001.962-14.74A1 1 0 009 0H7a1 1 0 00-.962 1.276A7.5 7.5 0 008 16.016m6.5-7.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0"/><path pid="1" d="M6.94 7.44l4.95-2.83-2.83 4.95-4.949 2.83 2.828-4.95z"/>',
    },
});
